import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["propertyOtherPartyInterestSubmitBtn"]
  connect() {
    this.propertyOtherPartyInterestSubmitBtnTarget.hidden = true;
  }

  remoteSubmitInterestedParty() {
    this.propertyOtherPartyInterestSubmitBtnTarget.click()
  }
}
