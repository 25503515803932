import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["vehicleHijackingSubmitBtn"]
  connect() {
    this.vehicleHijackingSubmitBtnTarget.hidden = true;
  }

  remoteSubmitHijackingDetails() {
    this.vehicleHijackingSubmitBtnTarget.click()
  }
}
