import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["vehicleClaimDriverEndorsementSubmitBtn"]
  connect() {
    this.vehicleClaimDriverEndorsementSubmitBtnTarget.hidden = true;
  }

  remoteSubmitVehicleClaimDriverEndorsementStatus() {
    this.vehicleClaimDriverEndorsementSubmitBtnTarget.click()
  }
}
