import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["propertyClaimIncidentVictimsSubmitBtn"]
  connect() {
    this.propertyClaimIncidentVictimsSubmitBtnTarget.hidden = true;
  }

  remoteSubmitIncidentVictims() {
    this.propertyClaimIncidentVictimsSubmitBtnTarget.click()
  }
}
