/* eslint no-console:0 */

import "@hotwired/turbo-rails"
import "chartkick/chart.js"
import 'flowbite';
require("@rails/activestorage").start()
require("local-time").start()

import "./channels"
import "./controllers"
import "./src/**/*"
import "flowbite/dist/flowbite.turbo.js";
