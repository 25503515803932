import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["vehicleClaimPassengerStatusSubmitBtn"]
  connect() {
    this.vehicleClaimPassengerStatusSubmitBtnTarget.hidden = true;
  }

  remoteSubmitVehicleClaimPassengerStatus() {
    this.vehicleClaimPassengerStatusSubmitBtnTarget.click()
  }
}
