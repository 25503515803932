// app/javascript/controllers/same_as_above_controller.js
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "contactPersonFirstName",
    "contactPersonLastName",
    "contactPersonContactNumber",
    "contactPersonEmail",
    "contactPersonPhysicalAddress",
    "assessmentContactPersonFirstName",
    "assessmentContactPersonLastName",
    "assessmentContactPersonTelephoneNumber",
    "assessmentContactPersonAddress",
    "sameAsAboveCheckbox"
  ]

  connect() {
    this.handleSameAsAboveCheckboxChange()
  }

  handleSameAsAboveCheckboxChange() {
    this.sameAsAboveCheckboxTarget.addEventListener("change", () => {
      if (this.sameAsAboveCheckboxTarget.checked) {
        this.populateAssessmentContactPersonFields()
      } else {
        this.clearAssessmentContactPersonFields()
      }
    })
  }

  populateAssessmentContactPersonFields() {
    this.assessmentContactPersonFirstNameTarget.value = this.contactPersonFirstNameTarget.value
    this.assessmentContactPersonLastNameTarget.value = this.contactPersonLastNameTarget.value
    this.assessmentContactPersonTelephoneNumberTarget.value = this.contactPersonContactNumberTarget.value
    this.assessmentContactPersonAddressTarget.value = this.contactPersonPhysicalAddressTarget.value
  }

  clearAssessmentContactPersonFields() {
    this.assessmentContactPersonFirstNameTarget.value = ""
    this.assessmentContactPersonLastNameTarget.value = ""
    this.assessmentContactPersonTelephoneNumberTarget.value = ""
    this.assessmentContactPersonAddressTarget.value = ""
  }
}