import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["liabilityClaimPermanentDisabilityDetailsSubmitBtn"]
  connect() {
    this.liabilityClaimPermanentDisabilityDetailsSubmitBtnTarget.hidden = true;
  }

  remoteSubmitPermanentDisabilityDetails() {
    this.liabilityClaimPermanentDisabilityDetailsSubmitBtnTarget.click()
  }
}
