// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { application } from "./application"

// Register each controller with Stimulus
import controllers from "./**/*_controller.js"
controllers.forEach((controller) => {
  application.register(controller.name, controller.module.default)
})

import { Dropdown, Modal, Tabs, Popover, Toggle, Slideover } from "tailwindcss-stimulus-components"
application.register('dropdown', Dropdown)
application.register('modal', Modal)
application.register('tabs', Tabs)
application.register('popover', Popover)
application.register('toggle', Toggle)
application.register('slideover', Slideover)

import Lightbox from '@stimulus-components/lightbox'
application.register('lightbox', Lightbox)

import SameAsAbove from './same_as_above_controller'
application.register('same-as-above', SameAsAbove)

import Flatpickr from 'stimulus-flatpickr'
application.register('flatpickr', Flatpickr)

import RailsNestedForm from '@stimulus-components/rails-nested-form'
application.register('nested-form', RailsNestedForm)

import ConditionFormElement from './conditional_form_element_controller'
application.register('condition-form-element', ConditionFormElement)

import ConditionalFormElementPropertyClaims from './conditional_form_element_property_claims_controller'
application.register('conditional-form-element-property-claims', ConditionalFormElementPropertyClaims)

import ConditionalFormElementPropertyClaimsVictims from './conditional_form_element_property_claims_victims_controller'
application.register('conditional-form-element-property-claims-victims', ConditionalFormElementPropertyClaimsVictims)

import ConditionalFormElementPropertyClaimsPreviousLoss from './conditional_form_element_property_claims_previous_loss_controller'
application.register('conditional-form-element-property-claims-previous-loss', ConditionalFormElementPropertyClaimsPreviousLoss)

import ConditionalFormElementPropertyClaimsOtherPartyInterest from './conditional_form_element_property_claims_other_party_interest_controller'
application.register('conditional-form-element-property-claims-other-party-interest', ConditionalFormElementPropertyClaimsOtherPartyInterest)

import ConditionalFormElementPropertyClaimsInsuredElsewhere from './conditional_form_element_property_claims_insured_elsewhere_controller'
application.register('conditional-form-element-property-claims-insured-elsewhere', ConditionalFormElementPropertyClaimsInsuredElsewhere)

import ConditionalFormElementLiability from './conditional_form_element_liability_controller'
application.register('conditional-form-element-liability', ConditionalFormElementLiability)

import ConditionalFormElementPublicLiability from './conditional_form_element_public_liability_controller'
application.register('conditional-form-element-public-liability', ConditionalFormElementPublicLiability)

import ConditionalFormElementHealthClaims from './conditional_form_element_health_claims_controller'
application.register('conditional-form-element-health-claims', ConditionalFormElementHealthClaims)

import ConditionalFormElementVehicleClaims from './conditional_form_element_vehicle_claims_controller'
application.register('conditional-form-element-vehicle-claims', ConditionalFormElementVehicleClaims)

import ConditionalFormElementVehicleHijacking from './conditional_form_element_vehicle_hijacking_controller'
application.register('conditional-form-element-vehicle-hijacking', ConditionalFormElementVehicleHijacking)

import ConditionalFormElementVehicleClaimPassengerStatus from './conditional_form_element_vehicle_claim_passenger_status_controller'
application.register('conditional-form-element-vehicle-claim-passenger-status', ConditionalFormElementVehicleClaimPassengerStatus)

import ConditionalFormElementVehicleClaimsWitnesses from './conditional_form_element_vehicle_claims_witnesses_controller'
application.register('conditional-form-element-vehicle-claims-witnesses', ConditionalFormElementVehicleClaimsWitnesses)

import ConditionalFormElementVehicleClaimOtherDamagedVehicle from './conditional_form_element_vehicle_claim_other_damaged_vehicle_controller'
application.register('conditional-form-element-vehicle-claim-other-damaged-vehicle', ConditionalFormElementVehicleClaimOtherDamagedVehicle)

import ConditionalFormElementLiabilityMotorOwnVehicle from './conditional_form_element_liability_motor_claim_own_vehicle_controller'
application.register('conditional-form-element-liability-motor-own-vehicle', ConditionalFormElementLiabilityMotorOwnVehicle)

import ConditionalFormElementLiabilityPermanentDisabilityDetails from './conditional_form_element_liability_permanent_disability_details_controller'
application.register('conditional-form-element-liability-permanent-disability-details', ConditionalFormElementLiabilityPermanentDisabilityDetails)

import ConditionalFormElementLiabilityClaimOffDutyDates from './conditional_form_element_liability_claim_off_duty_dates_controller'
application.register('conditional-form-element-liability-claim-off-duty-dates', ConditionalFormElementLiabilityClaimOffDutyDates)

import ConditionalFormElementVehicleClaimDriverEndorsement from './conditional_form_element_vehicle_claim_driver_endorsement_controller'
application.register('conditional-form-element-vehicle-claim-driver-endorsement', ConditionalFormElementVehicleClaimDriverEndorsement)

import ConditionalFormElementDeathClaimFactorsToDeathCause from './conditional_form_element_death_claim_factors_to_death_cause_controller'
application.register('conditional-form-element-death-claim-factors-to-death-cause', ConditionalFormElementDeathClaimFactorsToDeathCause)

import DarkToggle from './dark_toggle_controller'
application.register('dark-toggle', DarkToggle)

import SignaturePad from './signature_pad_controller'
application.register('signature-pad', SignaturePad)

import TextareaAutogrow from 'stimulus-textarea-autogrow'
application.register('textarea-autogrow', TextareaAutogrow)
