import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["liabilityClaimOffDutyDatesSubmitBtn"]
  connect() {
    this.liabilityClaimOffDutyDatesSubmitBtnTarget.hidden = true;
  }

  remoteSubmitOffDutyDates() {
    this.liabilityClaimOffDutyDatesSubmitBtnTarget.click()
  }
}
