import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["deathClaimFactorSubmitBtn"]
  connect() {
    this.deathClaimFactorSubmitBtnTarget.hidden = true;
  }

  remoteSubmitDeathCauseFactors() {
    this.deathClaimFactorSubmitBtnTarget.click()
  }
}
