import { Controller } from "@hotwired/stimulus";
import SignaturePad from "signature_pad";

export default class extends Controller {
  static targets = ["canvas", "input"];

  connect() {
    this.signaturePad = new SignaturePad(this.canvasTarget, {
      backgroundColor: 'rgb(255, 255, 255)' // Don't change this or the image will be black
    });
    this.resizeCanvas();
    window.addEventListener("resize", this.resizeCanvas.bind(this));
  }

  disconnect() {
    window.removeEventListener("resize", this.resizeCanvas.bind(this));
  }

  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvasTarget.width = this.canvasTarget.offsetWidth * ratio;
    this.canvasTarget.height = this.canvasTarget.offsetHeight * ratio;
    const context = this.canvasTarget.getContext("2d");
    context.scale(ratio, ratio);
    this.signaturePad.clear();
  }

  clear() {
    this.signaturePad.clear();
  }

  save() {
    if (this.signaturePad.isEmpty()) {
      alert('You must sign to accept the Terms and Conditions');
      event.preventDefault();
    } else {
      // Convert signature to JPEG data URL
      const dataURL = this.signaturePad.toDataURL('image/jpeg');
      this.inputTarget.value = dataURL;
    }
  }
}
