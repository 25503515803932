import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["vehicleClaimOtherDamagedVehicleSubmitBtn"]
  connect() {
    this.vehicleClaimOtherDamagedVehicleSubmitBtnTarget.hidden = true;
  }

  remoteSubmitVehicleClaimOtherDamagedVehicleStatus() {
    this.vehicleClaimOtherDamagedVehicleSubmitBtnTarget.click()
  }
}
